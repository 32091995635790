import React from 'react';

const RemortgageFAQ = () => ([
    {
      title: 'When do you need to remortgage?',
      description: (
        <>
        The main reason for remortgaging is that your current mortgage deal comes to an end. If you don't remortgage before your deal expires, the lender will move you to their standard variable rate (SVR) which is much more expensive.<br /><br />
        You may also need to remortgage early when you move home, want to release cash for home improvements or move to a cheaper deal should interest rates fall. In this case, you are likely to need to pay an early repayment charge (ERC).
        </>
      ),
    },
    {
      title: 'What are the potential costs associated with remortgaging?',
      description: (
        <>
          There are additional fees and costs that you'll usually have to pay when you remortgage.<br/><br/>
          These fees include mortgage broker fees, arrangement fees from your new lender, legal fees (remortgage solicitor fees), valuation fees, stamp-duty fees, and a booking fee.<br /><br />
          Additionally, if you are remortgaging early you may also encounter an early repayment charge from the lender for exiting your deal before the mortgage agreement specifies.
        </>
      ),
    },
    {
      title: 'Why do I need to pay stamp duty?',
      description: (
        <>
          You'll have to pay stamp duty on the purchase of a new home if it is worth more than £125,000. If you're purchasing a buy-to-let property, the stamp duty fee will be even higher.<br/><br/>
          If you are a first-time buyer, you won't need to pay any stamp duty on the first £300,000 of a home worth less than £500,000.
        </>
      ),
    },
    {
      title: 'What is an early repayment charge?',
      description: (
        <>
          An early repayment charge is a penalty or fee charged by your existing lender if you pay off the mortgage earlier than the mortgage term or go over the agreed mortgage overpayment allowance.<br/><br/>
          There are also penalties involved if you exit your mortgage from your existing lender early to move to a new deal with a new lender.
        </>
      ),
    },
    {
      title: 'What is an arrangement fee?',
      description: (
        <>
          An arrangement fee is what you will need to pay the new lender for setting up the mortgage.<br/><br/>
          The cost of an arrangement fee can vary massively. It's usually charged as a percentage of your mortgage deal interest rate, which as it is a large value can add up so it's important to take this into consideration when you're going through your mortgage application. Speaking to a mortgage broker can help with understanding any costs.
        </>
      ),
    },
    {
      title: 'What are valuation mortgage valuations?',
      description: (
        <>
          A mortgage valuation is an assessment of the mortgage property's value, taken by the mortgage lender. This process comes with a valuation fee, which varies in cost depending on the property and mortgage deal.
        </>
      ),
    },
    {
      title: 'What role do credit scores play in remortgaging costs?',
      description: (
        <>
        Your credit score can help determine what interest rates you're eligible for. The better your credit report the better mortgage deals and interest rates you'll have access to and thus, will make your remortgaging costs cheaper.
        </>
      ),
    },
    {
      title: 'Is my mortgage cost likely to increase on my remortgage deal?',
      description: (
        <>
        The change in cost to your mortgage deal depends on various factors.<br /><br />
        One of the key contributors to the cost of your mortgage, whether it's a new mortgage or a remortgage, is the interest rates set by the Bank of England, which can have a huge effect on what you pay in interest and the cost of your monthly mortgage repayments. If benchmark interest rates have increased, then what you are paying interest in is also going to rise.
        </>
      ),
    },
    {
      title: 'Why do I need to pay a solicitor fee?',
      description: (
        <>
        On some mortgages, you may need to hire and pay a solicitor to arrange the paperwork for the mortgage deal. Even if the mortgage deal is not completed, you'll need to pay legal fees for the work done by the solicitor to that point.<br /><br />
        The cost of the fees depends on things like the type of property or if it's a mortgage or remortgage..
        </>
      ),
    },
    {
      title: 'Why do I need to pay a broker fee?',
      description: (
        <>
        A mortgage broker can help you find the best mortgage deals, which come with broker fees.<br /><br />
        The broker must disclose their fees before you star working with them on your mortgage or remortgage, which can be either a fixed amount or a percentage of the loan amount.<br /><br />
        Some brokers are fee-free as they can earn commissions directly from lenders, so it may be worth comparing broker fees before you go ahead.
        </>
      ),
    },
  ]);

export default RemortgageFAQ;
